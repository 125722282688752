export const columns = [
	{
		title: "成员姓名",
		width: "15%",
		dataIndex: "name",
	},
	{
		title: "职位",
		width: "15%",
		dataIndex: "industryCommitteeTypeName",
	},
	{
		title: "关联房屋",
		width: "25%",
		dataIndex: "buildingName",
		scopedSlots: { customRender: "house" },
	},
    {
		title: "创建时间",
		width: "20%",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	appUserId: [{ required: true, message: "请选择住户", trigger: "change" }],
	industryCommitteeTypeId: [{ required: true, message: "请选择职位", trigger: "change" }],
}