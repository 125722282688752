import httpService from "@/request"

///类型
// 查询所有业委会类型信息
export function getComList(params) {
	return httpService({
		url: `/user/industryCommitteeType/list`,
		method: 'get',
		params: params,
	})
}

// 添加业委会类型
export function addComType(params) {
	return httpService({
		url: `/user/industryCommitteeType/insert`,
		method: 'post',
		data: params,
	})
}

// 修改业委会类型
export function updateComType(params) {
	return httpService({
		url: `/user/industryCommitteeType/update`,
		method: 'post',
		data: params,
	})
}

// 删除业委会类型
export function delComType(params) {
	return httpService({
		url: `/user/industryCommitteeType/delete`,
		method: 'get',
		params: params,
	})
}

///信息
// 查询所有业委会信息
export function getIndustryCommitteeList(params) {
	return httpService({
		url: `/user/industryCommittee/list`,
		method: 'get',
		params: params,
	})
}

// 添加业委会信息
export function addIndustryCom(params) {
	return httpService({
		url: `/user/industryCommittee/insert`,
		method: 'post',
		data: params,
	})
}

// 删除业委会信息
export function delIndustryCom(params) {
	return httpService({
		url: `/user/industryCommittee/delete`,
		method: 'post',
		data: params,
	})
}

// 根据业委会主键id查询业委会信息
export function findIndustryCom(params) {
	return httpService({
		url: `/user/industryCommittee/findById`,
		method: 'get',
		params: params,
	})
}

// 根据业委会主键id查询业委会信息
export function updateIndustryCom(params) {
	return httpService({
		url: `/user/industryCommittee/update`,
		method: 'post',
		data: params,
	})
}