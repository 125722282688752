<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>业委会</span>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.name" style="width: 200px" placeholder="成员名称"></a-input>
                    <a-select v-model="form.industryCommitteeTypeId" style="width: 200px" placeholder="职位">
                        <a-select-option v-for="item in typeList" :value="item.id" :key="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                    <a-range-picker v-model="addTime" @change="addTimeChange" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['创建开始时间','创建结束时间']"></a-range-picker>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="detailShow = true;mode = 1">添加人员</a-button>
        <a-button style="margin: 10px" @click="addType" type="primary" ghost>职位设置</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="house" slot-scope="text,record">
                    {{record.buildingName}}号楼/{{record.unitName}}单元/{{record.estateName}}室
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="mode==1?'添加成员':'编辑成员'" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <span>基本信息</span>
                <a-divider></a-divider>
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="11">
                        <a-form-model-item label="选择房屋" prop="estateId" :rules="[{ required: true, message: '请选择房屋' }]">
                            <a-cascader placeholder="请选择楼栋/单元/房屋" v-model="casVal" @change="chooseHouse" style="width: 240px" 
                                :field-names="{label: 'name', value: 'id', children: 'childList' }" :options="options.estateId">
                            </a-cascader>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="11">
                        <a-form-model-item label="成员姓名" prop="appUserId">
                            <a-select placeholder="请选择住户" style="width: 240px" v-model="addForm.appUserId">
                                <a-select-option v-for="(item) in applicantIds" :value="item.id" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="11">
                        <a-form-model-item label="选择职位" prop="industryCommitteeTypeId">
                            <a-select placeholder="请选择职位" style="width: 240px" v-model="addForm.industryCommitteeTypeId">
                                <a-select-option v-for="item in typeList" :value="item.id" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="11">
                        <a-form-model-item label="任职时间" prop="appointmentStartTime" :rules="[{ required: true, message: '请选择时间' }]">
                            <a-range-picker v-model=rzTime @change="rzTimeChange" style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['任职开始时间','任职结束时间']"></a-range-picker>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="成员照片">
                            <commonUpload :fileList='fileList' @handleChange="handleChange" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="备注" prop="remark">
                            <a-textarea placeholder="请输入备注，不超过1024个字符" style="width: 560px" :autoSize="{minRows: 8}" v-model="addForm.remark"></a-textarea>
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
        </a-drawer>
        <addType :show="addShow" @addClose="addShow = false" @refresh="getApi"></addType>
    </div>
</template>

<script>
import {findOwnerByEstateId} from "@/api/basic/resident"
import {findEstateCascade} from "@/api/basic/estate"
import {getComList, addIndustryCom, delIndustryCom, updateIndustryCom, findIndustryCom} from "@/api/operation/commission"
import {getIndustryCommitteeList} from "@/api/operation/commission"
import { rules, columns } from "./depend/config";
import addType from "./depend/addType.vue";
export default {
    components: {
		addType,
	},
    data() {
        return {
            form: {
                name: '',
                industryCommitteeTypeId: undefined,
                createDateStart: '',
                createDateEnd: '',
            },
            addForm: {
                estateId: undefined,
                appUserId: undefined,
                industryCommitteeTypeId: undefined,
                appointmentStartTime: '',
                appointmentEndTime: '',
                imgUrls: [],
                remark: '',
            },
            options: {
                estateId: []
            },
            mode: 1, //1新增 2编辑
            activeId: undefined,
            activeName: 0,
            rules: rules,
            columns: columns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            addTime: [],
            //详情
            applicantIds: [],
            casVal:[],
            fileList:[],
            rzTime: [],
            detailShow: false,
            //批量删除
            selectedRowKeys: [],
            //类型抽屉
            addShow: false,
            typeList: []
        }
    },
    created() {
        this.getApi()
        this.getData()
    },
    methods: {
        getApi() {
            getComList().then(res => {
                let data = res.data;
                this.typeList = data;
            });
            findEstateCascade().then(res => {
                let data = res.data;
                this.options.estateId = data;
            })
        },
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getIndustryCommitteeList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                name: '',
                industryCommitteeTypeId: undefined,
                createDateStart: '',
                createDateEnd: '',
            },
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        detailClose() {
            this.addForm = {
                estateId: undefined,
                appUserId: undefined,
                industryCommitteeTypeId: undefined,
                appointmentStartTime: '',
                appointmentEndTime: '',
                imgUrls: [],
                remark: '',
            },
            this.fileList = [],
            this.casVal = [],
            this.rzTime = [],
            this.detailShow = false
        },
        //修改任职时间
        rzTimeChange(val) {
            this.addForm.appointmentStartTime = val[0];
            this.addForm.appointmentEndTime = val[1];
        },
        addTimeChange(val) {
            this.form.createDateStart = val[0];
            this.form.createDateEnd = val[1];
        },
        //选择房屋的回调
        chooseHouse(val) {
            let v = val;
            let index = val.length
            this.addForm.estateId = v[index-1]
            this.addForm.appUserId = undefined;
            findOwnerByEstateId({estateId: v[index-1]}).then(res => {
                let data = res.data;
                this.applicantIds = data;
            })
        },
        //添加
        addConfirm() {
            if(this.mode == 1) {
                addIndustryCom(this.addForm).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                let obj = Object.assign(this.addForm, {id: this.activeId})
                updateIndustryCom(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //编辑
        edit(val) {
            this.mode = 2;
            findIndustryCom({industryCommitteeId: val.id}).then(res => {
                let data = res.data;
                findOwnerByEstateId({estateId: data.estateId}).then(res => {
                    let data = res.data;
                    this.applicantIds = data;
                })
                this.addForm = {
                    estateId: data.estateId,
                    appUserId: data.appUserId,
                    industryCommitteeTypeId: data.industryCommitteeTypeId,
                    appointmentStartTime: data.appointmentStartTime,
                    appointmentEndTime: data.appointmentEndTime,
                    imgUrls: [],
                    remark: data.remark,
                }; //图片的回调
                if(data.imgList.length>0){
					const pic = []
					this.addForm.imgUrls.push(data.imgList[0].url)
					for(let item of data.imgList){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						pic.push(obj)
					}
					this.fileList = pic
				}
                this.casVal = [data.buildingId,data.unitId,data.estateId];
                this.rzTime = [data.appointmentStartTime,data.appointmentEndTime];
                this.activeId = data.id
            })
            this.detailShow = true;
        },
        //删除
        del(val) {
            this.$confirm({
                    title: "是否删除该成员？",
                    icon:'delete',
                    onOk:async()=>{
                        let res = await delIndustryCom({industryCommitteeIds: [val.id]})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
            })
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的成员？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delIndustryCom({industryCommitteeIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        //新增电话类型
        addType() {
            this.addShow = true
        },
        //图片上传
        handleChange(data) {
            this.fileList = data;
			this.addForm.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.addForm.imgUrls.push(ele.response.data)
				}
			})
        }
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>